import React, { useRef } from 'react';
import styled from 'styled-components';
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; 

import { ReactComponent as CancelIcon } from '../../img/close.svg';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import Block from '../common/Block';
import Button from '../common/Button';
import Icon from '../common/Icon';
import Text from '../common/Text';

const JSX_MODAL = (props) => {
    const { t, i18n } = useTranslation();

    const { children,
        label = t("Save"),
        onClose,
        onSave,
        disabled,
        title,
        open,
        noPaddingRight
    } = props;

    useLockBodyScroll(open);
    
    const ref = useRef();

    const handleClick = (event) => {
        if (ref.current.contains(event.target)) {
            event.stopPropagation();

            return;
        }

        onClose();
    };

    return (
        <TransitionGroup>
            { open && 
                <CSSTransition classNames='modalTransition'
                    timeout={ 300 }
                    unmountOnExit
                >
                    <Window onClick={ handleClick }>
                        <Root ref={ ref }>
                            <Header center
                                noBorder
                            >   
                                <CloseIcon onClick={ onClose } 
                                    icon={ <CancelIcon /> }
                                    size={ 24 }
                                />

                                <Text bold>
                                    { title }
                                </Text>

                                <Done label={ label }
                                    onClick={ onSave }
                                    disabled={ disabled }
                                    noPaddingRight={ noPaddingRight }
                                />
                            </Header>

                            <Content>
                                { children }
                            </Content>
                        </Root>
                    </Window>
                </CSSTransition>
            }
         </TransitionGroup>
    );
}

function ModalFull(props) {
    return ReactDOM.createPortal(<JSX_MODAL { ...props } />, document.querySelector("#modal"));
};

export default ModalFull;

const Header  = styled(Block)`
    align-items: center;
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
    border-radius: 8px 8px 0 0;
    height: 56px;
`;

const Done  = styled(Button)`
    position: absolute;
    right: ${ ({ noPaddingRight }) => noPaddingRight ? 0 : '20px' };
`;

const CloseIcon  = styled(Icon)`
    position: absolute;
    left: 0;
`;

const Root  = styled.div`
    background-color: ${ ({ theme }) => theme.body };
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    position: relative;
`;


const Content  = styled.div`
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 76px;
`;

const Window  = styled.div`
    background-color: ${ ({ theme }) => theme.overlay };
    bottom: 0;
    left: 0;
    padding-top: 24px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 3;

    &.modalTransition-enter {
        opacity: 0;

        ${ Root } {
            transform: translateY(100%);
        }
    }

    &.modalTransition-enter-active {  
        opacity: 1;
        transition: ${ ({ theme }) => theme.transition };

        ${ Root } {
            transform: translateY(0);
            transition: ${ ({ theme }) => theme.transition };
        }
    }

    &.modalTransition-exit {
        opacity: 1;

        ${ Root } {
            transform: translateY(0);
        }
    }
    
    &.modalTransition-exit-active {  
        transition: ${ ({ theme }) => theme.transition };
        opacity: 0;
        
        ${ Root } {
            transform: translateY(100%);
            transition: ${ ({ theme }) => theme.transition };
        }
    }
`;
