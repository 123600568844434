import React, { useState, useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';

import { addToCart } from '../cart/cartSlice';
import { AuthContext } from '../../../Auth';
import { Captions, StyledCaption, StyledBlock, TextareaBlock, Blocks, ContentWrapper } from '../AddNewItem';
import { resizeImage } from '../../common/ImageUpload';
import Block from '../../common/Block';
import Body01 from '../../common/typography/Body01';
import Body02 from '../../common/typography/Body02';
import Button from '../../common/Button';
import Chip from '../../common/Chip';
import db from '../../../config/firebase';
import Headline06 from '../../common/typography/Headline06';
import ImageUpload from '../../common/ImageUpload';
import Input from '../../common/Input';
import LoadingSpinner from '../../common/Loadings/LoadingSpinner';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Tag from '../../common/Tag';
import Text from '../../common/Text';
import Textarea from '../../common/Textarea';
import Toggle from '../../common/Toggle';

const Item = ({
    item,
    hiddenCategory,
    categories,
    userId,
    lang
}) => {
    const { t, i18n } = useTranslation();

    const itemsInCart = useSelector(state => state.cart.itemsInCart);
    const items = useSelector(state => state.items.items);

    const dispatch = useDispatch();

    const theme = useTheme();

    const [ itemName, setItemName ] = useState(item?.useOriginalName ? item.originalName : item.name ? item.name[lang] : item.originalName)
    const [ price, setPrice ] = useState(item.price || '')
    const [ description, setDescription ] = useState(item?.description ? item.description[lang] : item.originalDescription)
    const [ openItem, setOpenItem ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ openHide, setOpenHide ] = useState(false);
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ vegetarian, setVegetarian ] = useState(item.vegetarian || false);
    const [ vegan, setVegan ] = useState(item.vegan || false);
    const [ image, setImage ] = useState(item.imageUrl || null);
    const [ progress, setProgress ] = useState(false);
    const [ imageIsUploading, setImageIsUploading ] = useState(false);
    const [ useOriginalName, setUseOriginalName ] = useState(item.useOriginalName || false);
    const [ openEditPosition, setOpenEditPosition ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const filteredItems = items.filter(filteredItem => filteredItem.categoryId === item.categoryId && filteredItem.id !== item.id);

    useEffect(() => {
        if (openEdit) {
            setItemName(item?.useOriginalName ? item.originalName : item.name ? item.name[lang] : item.originalName);
            setPrice(item.price || '');
            setDescription(item?.description ? item?.description[lang] : item.originalDescription);
            setVegetarian(item.vegetarian || false);
            setVegan(item.vegan || false);
            setImage(item.imageUrl || null);
            setUseOriginalName(item.useOriginalName || null);
        }
    }, [ openEdit ]);


    const changePosition = (stamp) => {
        const timestampCopy = new Date(stamp.toDate());
        timestampCopy.setSeconds(stamp.toDate().getSeconds() - 1);

        docRef
        .doc(item.id).set({
            timestamp: firebase.firestore.Timestamp.fromDate(timestampCopy)
        }, { merge: true })

        setOpenEditPosition(false);
    };
    
    const hideItem = () => {
        docRef
        .doc(item.id).set({
            hidden: !item.hidden
        }, { merge: true })

        setOpenHide(false);
    };

    const deleteItem = () => {
        docRef
        .doc(item.id)
        .delete()

        setOpenDelete(false);
    };

    const handleImageChange = async (event) => {
        setImageIsUploading(true)
        try {
            const resizedImage = await resizeImage(event.target.files[0]);
            setImage(resizedImage);
            setImageIsUploading(false);
        } catch (err) {
          console.log(err);
        }
      }; 

    const handleImageDelete = () => {
        setImage(null);
    };

    const docRef = currentUser && db.firestore()
    .collection('users')
    .doc(currentUser.uid)
    .collection('items');

    const updateItem = async () => {
        setProgress(true);

        const storageRef = db.storage().ref(`images/${ currentUser.uid }/`);

        const imageRef = image && storageRef.child(`${ item.id }.JPEG`);

        let url = '';

        if (image && image === item.imageUrl) {
            url = item.imageUrl;
        }

        else if (!image && item.imageUrl) {
            const ref = storageRef.child(`${ item.id }.JPEG`);

            ref.delete();
            url = null;
        }

        else if (!image) {
            url = null;
        }

        else try { 
            await imageRef.put(image)
            .then(() => imageRef.getDownloadURL())
            .then((img) => url = img)
        } 
        catch(error) { alert(error) }
       
        docRef
        .doc(item.id).set({
            translate: 
            { 
                description: !item.originalDescription ? description : item.originalDescription

            },
            translated: 
                {   
                    name: { [lang]: itemName.trim() }, 
                    description: { [lang]: description.trim() }
                },
            imageUrl: url,
            price: price.trim(),
            vegetarian,
            vegan,
            useOriginalName
        }, { merge: true })

        setProgress(false);

        setOpenEdit(false);
    };

    if ((item.hidden === true || hiddenCategory) && currentUser?.uid !== userId) {
        return null;
    }

    const handlePriceClick = (e) => (
        e.stopPropagation(),
        dispatch(addToCart(item.id))
    );

    const RenderContent = ({ full }) => {
        const itemName = item?.useOriginalName ? item.originalName : item?.name ? item.name[lang] : item.originalName;
        const descriptionName = item.description ? item.description[lang] : item.originalDescription;
  
        return (
        <Content full={ full }>
            { full ?
                <StyledHeadline06 bold>
                    { itemName }
                </StyledHeadline06>
                : <Body01 bold>{ itemName }</Body01>
            }

            { item?.originalDescription &&
                (full ? <DescriptionBig>{ descriptionName }</DescriptionBig> : <DescriptionSmall color={ theme.textSecondary }>{ descriptionName }</DescriptionSmall>)
            }
            
            <Tags>  
                { item?.vegan && <StyledTag label={ t("Vegan") } /> }

                { item?.vegetarian && <StyledTag label={ t("Vegetarian") } /> }
            </Tags>

            { !full && 
                <StyledChip onClick={ handlePriceClick }
                    active={ itemsInCart.includes(item.id) }
                    withCheckmark
                >
                    € { Number(item.price).toFixed(2) }
                </StyledChip>
            }

            { full && <StyledButton primary
                remove={ itemsInCart.includes(item.id) }
                label={ itemsInCart.includes(item.id) ? `${ t("Remove") }  - € ${ Number(item.price).toFixed(2) }` : `${ t("Add") }  - € ${ Number(item.price).toFixed(2) }` } 
                onClick={ () => dispatch(addToCart(item.id), setOpenItem(false)) }
                width="100%"  

            /> }

        </Content>
)};

    return (
        <>
            <Root onClick={ () => setOpenItem(true) }
                hidden={ !!item.hidden || hiddenCategory }
            >
                <RenderContent />

                { item.imageUrl && <ImgContainer url={ item.imageUrl } /> }
            </Root>

            <ModalBottom onClose={ () => setOpenItem(false) }
                open={ openItem }
                close
            >
                <FullImgContainer>
                    { item.imageUrl && 
                        <img src={ item.imageUrl } 
                            alt="reaction menu"
                        /> 
                    }
                </FullImgContainer>

                <RenderContent full />

                { currentUser && currentUser.uid === userId &&
                    <Border>
                        <Block center
                            onClick={ () => (setOpenEdit(true), setOpenItem(false))  }
                        >
                            <Text>{ t("Edit") }</Text>
                        </Block>

                        <Block center
                            onClick={ () => (setOpenEditPosition(true), setOpenItem(false))  }
                        >
                            <Text>{ t("Change position") } </Text>  
                        </Block>

                        <Block center
                            onClick={ () => (setOpenHide(true), setOpenItem(false))  }
                        >
                            <Text>{ item.hidden ? t("Make visible") : t("Hide") } </Text>  
                        </Block>

                        <Block center
                            onClick={ () => (setOpenDelete(true), setOpenItem(false))  }
                        >
                            <Text red>{ t("Delete") }</Text>
                        </Block>
                    </Border>
                }
            </ModalBottom>

            <ModalFull onClose={ () => setOpenEdit(false) }
                disabled={ !itemName || !price }
                title={ t("Edit item") }
                onSave={ updateItem }
                open={ openEdit }
                label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
            >
                <ImageUpload onImageChange={ handleImageChange }
                    image={ image }
                    onDelete={ handleImageDelete }
                    inProgress={ imageIsUploading }
                />
               
                <ContentWrapper>
                    <Captions>
                        <StyledCaption required>{ t("Title") }</StyledCaption>

                        <StyledCaption required>{ t("Price") }</StyledCaption>

                        <StyledCaption>{ t("Description") }</StyledCaption>
                    </Captions>

                    <Blocks> 
                        <StyledBlock>
                            <Input value={ itemName }
                                label={ t("Title") }
                                onChange={ (e) => setItemName(e.target.value) }
                                required
                            />
                        </StyledBlock>

                        <StyledBlock>
                            <Input value={ price }
                                label={ t("Price") }
                                onChange={ (e) => setPrice(e.target.value) }
                                placeholder="0.00"
                                required
                                type="number"
                                step="0.01"
                            />
                        </StyledBlock>

                        <TextareaBlock>
                            <Textarea value={ description }
                                onChange={ (e) => setDescription(e.target.value) }
                                placeholder={ t("Write description here") }
                            />
                        </TextareaBlock>
                    </Blocks>
                </ContentWrapper>

                <Block>
                    <Text bold>Don't translate title</Text>

                    <Toggle checked={ useOriginalName }
                        onChange={ () => setUseOriginalName(!useOriginalName) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegetarian") }</Text>

                    <Toggle checked={ vegetarian }
                        onChange={ () => setVegetarian(!vegetarian) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegan") }</Text>

                    <Toggle checked={ vegan }
                        onChange={ () => setVegan(!vegan) }
                    />
                </Block>
            </ModalFull> 

            <ModalBottom onClose={ () => setOpenEditPosition(false) }
                title={ t('Put before...') }
                open={ openEditPosition }
            >
                { filteredItems && filteredItems.map(item => (
                    <Block onClick={ () => changePosition(item.timestamp) }
                        key={ item.id }
                    >
                        <Body01>
                            {  item?.name ? item.name[lang] : item.originalName }
                        </Body01>
                    </Block>
                ))}
            </ModalBottom>

            <ModalBottom onClose={ () => setOpenHide(false) }
                title={ item.hidden ? t('Make item visible?') : t('Hide item?') }
                open={ openHide }
            >
                <Block center noBorder>
                    <Text small center>The item will be { item.hidden === true ? 'visible' : 'invisible'} to the customers</Text>
                </Block>

                <Block center noBorder>
                    <Button label={ item.hidden ? t('Make visible') : t('Hide')}
                        primary 
                        onClick={ hideItem }
                        width="100%"
                    />
                </Block>
            </ModalBottom>

            <ModalBottom onClose={ () => setOpenDelete(false) }
                title={ t('Delete item?') }
                open={ openDelete }
            >
                <Block center noBorder>
                    <DeleteButton onClick={ deleteItem }
                        label={ t("Delete") }
                        primary
                        width="100%"
                    />
                </Block>
            </ModalBottom>
        </>
    )
}

export default Item;

const Border = styled.div`
    border-top: 1px solid ${ ({ theme }) => theme.border }
`;

const StyledButton = styled(Button)`
    background-color: ${ ({ theme, remove }) => remove && theme.red };
    margin-top: 16px;
`;

const DeleteButton = styled(Button)`
    background-color: ${ ({ theme }) => theme.red }
`;

const Root = styled.div`
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0;
    padding: 16px 0;
    opacity: ${ ({ hidden }) => hidden ? '0.3' : 1 };
`;

const Content = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: ${ ({ full }) => full ? '16px': '0 12px 0 0' };
    min-width: 0;
    position: relative;
`;

const ImgContainer = styled.div`
    background-image: url(${ ({ url }) => url });
    background-position:center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    width: 30%;
    padding-bottom: 30%;
    border-radius: 8px;
`;

const FullImgContainer = styled.div`
   img {
       border-radius: 8px 8px 0 0;
   }
`;

const StyledChip = styled(Chip)`
    margin-top: 16px;
`;

const Tags = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledTag = styled(Tag)`
    margin-top: 12px;
`;

const StyledHeadline06 = styled(Headline06)` 
    margin-right: 64px;
`;

const DescriptionSmall = styled(Body02)`
    margin-top: 8px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DescriptionBig = styled(Body01)`
    margin-top: 8px;
`;