import React, { useState, useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../Auth';
import { ReactComponent as UserIcon } from '../../../img/account.svg';
import { ReactComponent as YoutubeShareIcon } from '../../../img/youtubeShare.svg';
import Block from '../../common/Block';
import Button from '../../common/Button';
import db from '../../../config/firebase';
import Icon from '../../common/Icon';
import ImageUpload from '../../common/ImageUpload';
import Input from '../../common/Input';
import LoadingSpinner from '../../common/Loadings/LoadingSpinner';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Body02 from '../../common/typography/Body02';
import Body01 from '../../common/typography/Body01';

const SettingsButton = ({
    className,
    businessName,
    logo, 
    phone
}) => {
    const { currentUser } = useContext(AuthContext);
    const { t, i18n } = useTranslation();

    const [ open, setOpen ] = useState(false);
    const [ openName, setOpenName ] = useState(false);
    const [ openLogo, setOpenLogo ] = useState(false);
    const [ openPhone, setOpenPhone ] = useState(false);
    const [ image, setImage ] = useState(logo || null);
    const [ progress, setProgress ] = useState(false);
    const [ name, setName ] = useState(businessName || '');
    const [ phoneNumber, setPhoneNumber ] = useState(phone || '');
    const [ openQRcode, setOpenQRcode ] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        setName(businessName);
    }, [ openName ]);

    useEffect(() => {
        setImage(logo);
    }, [ openLogo ]);

    useEffect(() => {
        setPhoneNumber(phone);
    }, [ openPhone ]);

    const handleLogout = () => {
        setOpen(false);
        db.auth().signOut();
        localStorage.removeItem('activeCategory');
    };

    const docRef = db.firestore().collection("users").doc(currentUser.uid);

    const handleUpdateBusinessName = () => {
        docRef
			.set({
				displayName: name
			}, { merge: true })

        setOpenName(false);
    };

    const handleUpdatePhone = () => {
        docRef
			.set({
				phone: phoneNumber
			}, { merge: true })

        setOpenPhone(false);
    };

    const url = window.location.href;

    const downloadQR = () => {
        const canvas = document.getElementById("QRCode");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QRCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleLogoChange = (event) => {
        setImage(event.target.files[0]);
      }; 

    const handleLogoDelete = () => {
        setImage(null);
    };

    const handleLogoUpdate = async () => {

        setProgress(true);

        const storageRef = db.storage().ref(`images/${ currentUser.uid }/logo`);

        const imageRef = image && storageRef.child(image.name);

        let url = '';

        if (image) {
            try { 
                await imageRef.put(image)
                .then(() => imageRef.getDownloadURL())
                .then((img) => url = img)
            } 
            catch(error) { alert(error) }
        } 

        const docRef = db.firestore().collection("users").doc(currentUser.uid);

		docRef
		.set({
			logoUrl: url
		}, { merge: true });

        setOpenLogo(false);
        setProgress(false);
    };

    const handleShareQRcode = () => {
        if (navigator.share) {
            navigator
              .share({
                title: 'Reaction menu',
                text: `Digital menu of ${ businessName }`,
                url: document.location.href
              })
              .then(() => {
                console.log('Successfully shared');
              })
              .catch(error => {
                console.error('Something went wrong sharing the menu', error);
              });
          }
    };

    return (
        <Root className={ className }>
            <Icon onClick={ () => setOpen(true) } 
                icon={ <UserIcon /> } 
                color={ theme.body }
            />

            <ModalBottom open={ open }
                onClose={ () => setOpen(false) }
                title={ t('Settings') }
            >
                    <Block center
                        onClick={ () => (setOpenLogo(true), setOpen(false)) }
                    >
                        <Body01>{ t('Set logo') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenName(true), setOpen(false)) }
                    >
                        <Body01>{ t('Business name') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenPhone(true), setOpen(false)) }
                    >
                        <Body01>{ t('Phone number') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenQRcode(true), setOpen(false))  }
                    >
                        <Body01>{ t('Download QR code') }</Body01>
                    </Block>

                    <Block onClick={ handleLogout }
                        center
                    >
                        <Body01>{ t('Logout') }</Body01>
                    </Block>
            </ModalBottom>

            <ModalFull onClose={ () => setOpenQRcode(false) }
                title="QR code"
                open={ openQRcode }
                onSave={ handleShareQRcode }
                label={ <Icon icon={ <YoutubeShareIcon /> } /> }
                noPaddingRight
            >
                <Block center noBorder>
                    <Body02 color={ theme.textSecondary }> 
                        Print and place this QR code on tables, walls, paper menus - guests will scan it with their smartphone camera to open the menu.</Body02>
                 </Block>

                 <Block center noBorder>
                    <StyledQRCode id="QRCode"
                        value={ url }
                        size={ 200 }
                    /> 
                 </Block>
                
                 <Block center noBorder>
                    <Button primary 
                        onClick={ downloadQR } 
                        label={ t("Download") } 
                        width="100%"
                    />
                </Block>
            </ModalFull> 

            <ModalFull onClose={ () => setOpenName(false) }
                title={ t('Business name') }
                open={ openName }
                onSave={ handleUpdateBusinessName }
                disabled={ name === businessName }
            >
                <Block>
                    <Input value={ name }
                        onChange={ (e) => setName(e.target.value) }
                        placeholder={ t('Business name') }
                        autoFocus
                    />
                </Block>
            </ModalFull> 
            
            <ModalFull onClose={ () => setOpenLogo(false) }
                title= { t('Set logo') }
                open={ openLogo }
                onSave={ handleLogoUpdate }
                label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
            >
                <Block center>
                    <Body02 color={ theme.textSecondary }> 
                        Replace business name by logo.<br />
                        svg, png or jpg formats
                    </Body02>
                </Block>

                <ImageUpload userId={ currentUser.uid } 
                    onImageChange={ handleLogoChange }
                    image={ image }
                    onDelete={ handleLogoDelete }
                    deleteLabel={ t("Delete logo") }
                />
            </ModalFull>


            <ModalFull onClose={ () => setOpenPhone(false) }
                title={ t('Phone number') }
                open={ openPhone }
                onSave={ handleUpdatePhone }
                disabled={ phoneNumber === phone }
            >
                <Block>
                    <Body02 color={ theme.textSecondary }> 
                        Allow customers to call you. Phone icon will appear at the right top corner of your menu
                   </Body02>
                </Block>
                <Block>
                    <Input value={ phoneNumber }
                        onChange={ (e) => setPhoneNumber(e.target.value) }
                        placeholder={ t('Phone number') }
                        autoFocus
                    />
                </Block>
            </ModalFull> 
        </Root>
    );
}

export default SettingsButton;

const Root = styled.div`
`;

const StyledQRCode = styled(QRCode)`
  
`;

