import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from '../../../img/close.svg';
import { removeFromCart, getTotalPrice } from './cartSlice';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import ModalBottom from '../../common/ModalBottom';
import Body01 from '../../common/typography/Body01';
import Body02 from '../../common/typography/Body02';

const Cart = ({
    lang
}) => {
    const { t, i18n } = useTranslation();

    const theme= useTheme();

    const items = useSelector(state => state.items.items);
    const itemsInCart = useSelector(state => state.cart.itemsInCart);
    const totalPrice = useSelector(getTotalPrice);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const getLabel = () => (
        <>
            { t("View order") } - 
            <Counter>€ { totalPrice }</Counter>
        </>
    );

    return (
        <>
            { itemsInCart?.length > 0 && 
                <Root>
                    <StyledButton label={ getLabel() }
                        primary
                        width="100%"
                        onClick={ () => setOpen(true) }
                    />

                    <ModalBottom onClose={ () => setOpen(false) }
                        open={ open }
                        title={ t("Call the staff to order") }
                    >   
                        <CartContent>
                            { itemsInCart && itemsInCart.map((itemId, index) => {
                            const found = items.find(item => itemId === item.id)
                            return (
                                    <ListItem key={ itemId }>
                                        <Item>
                                            <Body01>{ index+1 }. { found.name[lang] }</Body01>
                                            <Body02 color={ theme.textSecondary }> € { found.price }</Body02>
                                        </Item>

                                        <Icon onClick={ () => dispatch(removeFromCart(found.id)) } 
                                            icon={ <DeleteIcon /> }
                                            color= { theme.textSecondary }
                                            size={ 20 }
                                        />
                                    </ListItem> 
                                )
                            }) }   

                            <Total bold>Total: € { totalPrice }</Total>  
                             
                        </CartContent>
                    </ModalBottom>
                </Root>
            }
        </>
    );
}

export default Cart;

const Root  = styled.div`
    background: ${({ theme }) => theme.body };
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 2;
`;

const Item  = styled.div`
`;

const Total  = styled(Body01)`
    margin-top: 24px;
`;

const StyledButton  = styled(Button)`
    border-radius: 0;
    background: ${({ theme }) => theme.text };
`;

const Counter  = styled.span`
    margin-left: 6px;
`;

const ListItem  = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const CartContent  = styled.div`
    padding: 0 16px;
`;
