import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import styled from 'styled-components';

import { AuthContext } from '../../Auth';
import { useTranslation } from 'react-i18next';
import Block from '../common/Block';
import ModalBottom from '../common/ModalBottom';
import Text from '../common/Text';
import db from '../../config/firebase';
import RadioInput from '../common/RadioInput';
import Button from '../common/Button';

const SubscriptionModal = ({
    onClose,
    open
}) => {

    const { currentUser } = useContext(AuthContext);

    const YEARLY_PRICE = 'price_1Jig7qFEWEsMSzk33GHKmWrP';
    const MONTHLY_PRICE = 'price_1Jig79FEWEsMSzk3SyrBoKmY';

    const [ price, setPrice ] = useState(YEARLY_PRICE);

    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
    }, [ open ]);

    const theme = useTheme();

    const { t, i18n } = useTranslation();

    const handleCheckout = async event => {
        event.preventDefault();
        const userDocRef = db.firestore().collection("users").doc(currentUser.uid);

        setLoading(true);

        const checkoutRef = await userDocRef
            .collection('checkout_sessions')
            .add({
                price: price,
                success_url: `${ window.location.origin }/${ currentUser.uid }`,
                cancel_url: `${ window.location.origin }/${ currentUser.uid }`,
            });
            // Wait for the CheckoutSession to get attached by the extension
            checkoutRef.onSnapshot((snap) => {
                const { error, url } = snap.data();
                if (error) {
                    setLoading(false);
                    setError(error.message);
                }
                if (url) {
                     // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                    }
            });
    };


    return (
        <ModalBottom onClose={ () => {} }
            title= { t('Choose your subscription') }
            open={ open }
    >
        <Block center>
        <form onSubmit={ handleCheckout }>
                <Radios>
                    <StyledRadioInput label="290 euros/yearly"
                        value={ YEARLY_PRICE }
                        onChange = { event => setPrice(event.target.value) }
                        checked={ price === YEARLY_PRICE }
                    />

                    <StyledRadioInput label="29 euros/monthly"
                        value={ MONTHLY_PRICE }
                        onChange={ event => setPrice(event.target.value) }
                        checked={ price === MONTHLY_PRICE }
                    />
                </Radios>

                <Error small red>{ error }</Error>

                <Button label={ loading ? "Processing..." : "Subscribe" }
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </form>
        </Block>

    </ModalBottom>
    )};

export default SubscriptionModal;

const StyledRadioInput = styled(RadioInput)`
    margin-bottom: 24px;
`;

const Radios = styled.div`
    margin: 16px 0 32px;
`;

const Error = styled(Text)`
    margin-bottom: 12px;
    text-align: center;
`;