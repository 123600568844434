import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '../../img/add.svg';
import Block from '../common/Block';
import ModalBottom from '../common/ModalBottom';
import AddEditCategory from './AddCategory';
import AddNewItemModal from './AddNewItem';
import Icon from '../common/Icon';
import Text from '../common/Text';
import AddNewStoryModal from '../features/Story/AddNewStoryModal';

const AddButton = ({
    className,
    categories,
    lang
}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [ open, setOpen ] = useState(false);
    const [ openNewCategory, setOpenNewCategory ] = useState(false);
    const [ openNewItem, setOpenNewItem ] = useState(false);
    const [ openNewStory, setOpenNewStory ] = useState(false);

    const handleClick = () => {
        setOpen(true);
    }

    const handleOpenNewCategory = () => {
        setOpen(false);
        setOpenNewCategory(true);
    }

    const handleOpenNewItem = () => {
        setOpen(false);
        setOpenNewItem(true);
    }

    const handleOpenNewStory= () => {
        setOpen(false);
        setOpenNewStory(true);
    };

    return (
        <Root className={ className }>
            <Icon icon={ <AddIcon /> } 
                onClick={ handleClick }
                color={ theme.body }
            />

            <ModalBottom open={ open }
                title={ t('Create') }
                onClose={ () => setOpen(false) }
            >
                    <Block center
                        onClick={ handleOpenNewCategory }
                    >
                        <Text>{ t('Category') }</Text>
                    </Block>

                        <Block center
                            onClick={ handleOpenNewItem }
                            disabled={ categories.length === 0 }
                        >
                             <Text disabled={ categories.length === 0 }>
                                 { t('Item') }
                            </Text>
                        </Block>

                        <Block center
                            onClick={ handleOpenNewStory }
                        >
                             <Text>
                                 { t('Story') }
                            </Text>
                        </Block>
                </ModalBottom>
            
                <AddEditCategory open={ openNewCategory }
                    onClose={ () => setOpenNewCategory(false) }
                />

                <AddNewItemModal onClose={ () => setOpenNewItem(false) }
                    categories={ categories }
                    open={ openNewItem }
                    lang={ lang }
                />

                <AddNewStoryModal onClose={ () => setOpenNewStory(false) }
                    open={ openNewStory }
                    lang={ lang }
                />
        </Root>
    );
}

export default AddButton;

const Root = styled.div`
  
`;
