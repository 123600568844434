import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../Auth';
import { resizeImage } from '../common/ImageUpload';
import Block from '../common/Block';
import CategoriesList from '../features/categories/CategoriesList';
import db from '../../config/firebase';
import ImageUpload from '../common/ImageUpload';
import Input from '../common/Input';
import LoadingSpinner from '../common/Loadings/LoadingSpinner';
import ModalFull from '../common/ModalFull';
import Text from '../common/Text';
import Textarea from '../common/Textarea';
import Toggle from '../common/Toggle';
import Caption from '../common/Caption';

const AddNewItem = ({
    categories,
    onClose,
    fixedCategory,
    open,
    lang
}) => {
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    const [ itemName, setItemName ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ activeCategory, setActiveCategory ] = useState(() => {
        const saved = localStorage.getItem("activeCategory");
        const localStorageValue = JSON.parse(saved);
        return localStorageValue || categories[0] || "";
      });
    const [ vegan, setVegan ] = useState(false);
    const [ vegetarian, setVegetarian ] = useState(false);

    const [ image, setImage ] = useState(null);
    const [ progress, setProgress ] = useState(false);
    const [ imageIsUploading, setImageIsUploading ] = useState(false);
    const [ useOriginalName, setUseOriginalName ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const storageRef = db.storage().ref(`images/${ currentUser?.uid }/`);
    
    const handleImageChange = async (event) => {
        setImageIsUploading(true)
        try {
            const resizedImage = await resizeImage(event.target.files[0]);
            setImage(resizedImage);
            setImageIsUploading(false);
        } catch (err) {
          console.log(err);
        }
      };

      const handleImageDelete = async () => {
        setImage(null);
    };

    const addItem = async (e) => {
        e.preventDefault();
        setProgress(true);

        const imageRef = image && storageRef.child(image.name);

        let url = '';

        if (image) {
            try { 
                await imageRef.put(image)
                .then(() => imageRef.getDownloadURL())
                .then((img) => url = img)
            } 
            catch(error) { alert(error) }
        } 

        db.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('items')
            .add({
                category: fixedCategory ? fixedCategory.id : activeCategory.id,
                translate: { name: itemName, description },
                imageUrl: url,
                price: price.trim(),
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                vegan,
                vegetarian,
                hidden: false,
                useOriginalName
            })
        
        setProgress(false);

        onClose();
    };

    useEffect(() => {
        if (open) {setItemName('');
        setPrice('');
        setDescription('');
        setVegan(false);
        setVegetarian(false);
        setImage(null);
        setUseOriginalName(false);
        setActiveCategory(() => {
            const saved = localStorage.getItem("activeCategory");
            const localStorageValue = JSON.parse(saved);
            return localStorageValue || categories[0] || "";
          })}
    },[ open ])

    useEffect(() => {
        localStorage.setItem("activeCategory", JSON.stringify(activeCategory));
      }, [ activeCategory ]);

    return (
        <ModalFull disabled={ !itemName || !price || !activeCategory }
                title={ t("New item") }
                onClose={ onClose  }
                onSave={ addItem }
                open={ open }
                label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
            >
                <ImageUpload onImageChange={ handleImageChange }
                        image={ image }
                        onDelete={ handleImageDelete }
                        inProgress={ imageIsUploading }
                />

                <ContentWrapper>
                    <Captions>
                        <StyledCaption required>{ t("Category") }</StyledCaption>

                        <StyledCaption required>{ t("Title") }</StyledCaption>

                        <StyledCaption required>{ t("Price") }</StyledCaption>

                        <StyledCaption>{ t("Description") }</StyledCaption>
                    </Captions>

                    <Blocks>
                        <StyledBlock>
                            { (fixedCategory && fixedCategory.name) ? <Text>{ fixedCategory.name[lang] }</Text>
                                : <CategoriesList categories={ categories }
                                    activeCategory={ activeCategory.id }
                                    onCategoryChange={ (category) => setActiveCategory(category) }
                                    lang={ lang }
                                />
                            }
                        </StyledBlock>
                
                        <StyledBlock>
                            <Input value={ itemName }
                                onChange={ (e) => setItemName(e.target.value) }
                                placeholder={ t("Title") }
                                required
                            />
                        </StyledBlock>

                        <StyledBlock>
                            <Input value={ price }
                                onChange={ (e) => setPrice(e.target.value) }
                                placeholder="0.00"
                                type="number"
                                step="0.01"
                                required
                            />
                        </StyledBlock>

                        <TextareaBlock noBorder>
                            <Textarea value={ description }
                                onChange={ (e) => setDescription(e.target.value) }
                                placeholder={ t("Description") }
                            />
                        </TextareaBlock>
                    </Blocks>
                </ContentWrapper>

                <Block>
                    <Text bold>Don't translate title</Text>

                    <Toggle checked={ useOriginalName }
                        onChange={ () => setUseOriginalName(!useOriginalName) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegetarian") }</Text>

                    <Toggle checked={ vegetarian }
                        onChange={ () => setVegetarian(!vegetarian) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegan") }</Text>

                    <Toggle checked={ vegan }
                        onChange={ () => setVegan(!vegan) }
                    />
                </Block>
        </ModalFull>
    );
}

export default AddNewItem;

export const Captions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCaption = styled(Caption)`
    padding: 0 16px;
    height: 56px;
    display: flex; 
    align-items: center;
`;

export const StyledBlock = styled(Block)`
    align-items: center;
    display: flex; 
    height: 56px;
    padding: 0;
`;

export const TextareaBlock = styled.div`
    padding: 16px 0 16px 0;
`;

export const Blocks = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
`;
