import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll'

import { AuthContext } from '../../Auth';
import NotFound from '../../components/common/NotFound';
import Body01 from '../../components/common/typography/Body01';

const Navigation = ({
    userId,
    lang,
    logo
}) => {
    const categories = useSelector(state => state.categories.categories);

    const { currentUser } = useContext(AuthContext);

    const user = currentUser && currentUser.uid === userId;

    if (!categories.length && user) {
        return (
         <NotFound title="The menu is empty" 
         text="Start creating categories by clicking + button at the right top corner"
     />
        )
    }

    if (!categories.length) {
        return (
            <NotFound title="The menu is empty or expired" 
                text="If you are store owner, contact us for details"
            />);
    }

    return (
        <Root user={ user }
            logo={ logo }
        >
             { categories && categories.map((category) => (
                    (user || !category.hidden) &&
                    <StyledLink to={ category.id }
                        spy={ true }
                        smooth={ true }
                        offset={ user ? -186 : -120 }
                        activeClass='active'
                        key={ category.id }
                        >
                            <StyledBody01>
                                { category && category.name ? category.name[lang] : category.originalName }
                            </StyledBody01>
                    </StyledLink>
                )) }
        </Root>
    )
};

export default Navigation;

const Root = styled.div`
    align-items: center;
    background: ${ ({ theme }) => theme.body };
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;
    position: sticky;
    scrollbar-width: none;
    top: ${ ({ user }) => user ? '128px' : '64px' };
    z-index: 2;
    
    ::-webkit-scrollbar {
        display: none;
      }
`;

const StyledBody01 = styled(Body01)`
    color: ${ ({ theme }) => theme.text };
    white-space: nowrap;
    line-height: 1;
    align-items: center;
    display: flex;
`;

const StyledLink = styled(Link)`
    padding: 12px 16px;
    border-radius: 32px;
    border: 1px solid ${ ({ theme }) => theme.border };
    margin-right: 12px;

    &:first-of-type {
        margin-left: 16px;
    }

    &.active {
        background: ${ ({ theme }) => theme.border };
    }
`;
